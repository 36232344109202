<template>
    <div class="pt-48">
        <footer class="px-4 pb-6 sm:px-8 lg:pb-20 bg-slate-800 lg:px-12">
            <!-- Cta Banner -->
            <div
                    class="relative py-12 -mb-12 overflow-hidden -translate-y-24 rounded-lg md:rounded-tr-full md:-translate-x-8 lg:py-24 bg-gradient-to-tl from-blue-700 via-blue-600 to-blue-500 lg:-translate-x-12 lg:-translate-y-32">
                <div class="absolute inset-0">
                    <img src="/images/cta-bg.png" alt="" class="object-cover w-full h-full opacity-20">
                </div>
                <div class="relative z-20 w-full max-w-screen-xl px-4 mx-auto lg:px-12">
                    <div
                            class="flex flex-col items-start space-y-6 md:pr-24 lg:justify-between lg:items-center lg:flex-row 2xl:pr-0 lg:space-y-0">
                        <div class="max-w-md space-y-6">
              <span class="text-2xl font-semibold md:text-4xl text-slate-100">
                Leverage our team of experts to build or maintain your web application.
              </span>
                        </div>
                        <a href="#"
                           class="inline-flex items-center px-4 py-3 space-x-1 font-semibold text-indigo-600 transition-colors duration-300 rounded-full bg-gradient-to-bl from-white via-indigo-100 to-white leading-0 hover:from-blue-600 hover:via-blue-700 hover:to-blue-500 hover:text-white">
            <span>
              Get in touch
            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M9 5l7 7-7 7"/>
                            </svg>
                        </a>
                    </div>
                    <!-- usps -->
                    <div
                            class="flex flex-col pt-6 space-y-3 md:space-y-0 md:space-x-6 md:items-center md:flex-row lg:pt-10 lg:space-x-10">
          <span class="flex items-center space-x-3">
            <span
                    class="flex items-center justify-center w-8 h-8 border-2 rounded-full md:w-10 md:h-10 border-opacity-20 border-indigo-50">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-indigo-100" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"/>
              </svg>
            </span>
            <span class="text-sm font-semibold lg:text-lg text-slate-200">
              Experienced Developers
            </span>
          </span>
                        <span class="flex items-center space-x-3">
            <span
                    class="flex items-center justify-center w-8 h-8 border-2 rounded-full md:w-10 md:h-10 border-opacity-20 border-indigo-50">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-indigo-100" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M11.933 12.8a1 1 0 000-1.6L6.6 7.2A1 1 0 005 8v8a1 1 0 001.6.8l5.333-4zM19.933 12.8a1 1 0 000-1.6l-5.333-4A1 1 0 0013 8v8a1 1 0 001.6.8l5.333-4z"/>
              </svg>
            </span>
            <span class="text-sm font-semibold lg:text-lg text-slate-200">
              Rapid Development Procedures
            </span>
          </span>
                        <span class="flex items-center space-x-3">
            <span
                    class="flex items-center justify-center w-8 h-8 border-2 rounded-full md:w-10 md:h-10 border-opacity-20 border-indigo-50">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 text-indigo-100" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"/>
              </svg>
            </span>
            <span class="text-sm font-semibold lg:text-lg text-slate-200">
              Dedicated Security Specialists
            </span>
          </span>
                    </div>
                    <!--/ usps -->
                </div>
            </div>
            <!--/ Cta Banner -->
            <!-- Footer -->
            <div class="max-w-screen-xl mx-auto">
                <div class="grid lg:grid-cols-2">
                    <div class="space-y-8">
                        <div class="space-y-3">
                            <a href=".">
                                <img src="/images/logo-white.svg" alt="Webdevs.com.au Logo" class="h-12">
                            </a>
                            <p class="italic tracking-wider text-slate-200" v-text="footerMenu.tagline"/>
                        </div>

                        <span class="block tracking-wider text-slate-200">Locations</span>

                        <div class="flex">
                            <address class="flex-1 not-italic leading-7 text-slate-400">
                                42 Westminster Street, <br />
                                Bexley, NSW
                            </address>

                            <address class="flex-1 not-italic leading-7 text-slate-400">
                                2C Arcadia Street, <br />
                                Adelaide, SA
                            </address>
                        </div>
                        <a href="mailto:info@webdevs.com.au" class="inline-block text-slate-400 hover:text-blue-600">info@webdevs.com.au</a>
                    </div>
                    <div class="pt-12 space-y-8 lg:pt-14">
                        <span class="block tracking-wider text-slate-200" v-text="footerMenu.navigationTitle"/>
                        <div class="grid grid-cols-2 gap-12 text-slate-400">

                            <nav class="space-y-2">
                                <a v-for="navItem in footerMenu.nav.filter((v, i) => !(i % 2))" :href="navItem.link.url"
                                   class="block hover:text-blue-600">
                                    {{ navItem.label }}
                                </a>
                            </nav>

                            <nav class="space-y-2">
                                <a v-for="navItem in footerMenu.nav.filter((v, i) => i % 2)" :href="navItem.link.url"
                                   class="block hover:text-blue-600">
                                    {{ navItem.label }}
                                </a>
                            </nav>
                        </div>
                    </div>
                </div>
                <div
                        class="flex flex-col pt-6 mt-12 space-y-4 border-t lg:space-y-0 lg:justify-between lg:items-center lg:flex-row border-slate-600 lg:pt-8 lg:mt-24">
                    <span class="text-slate-400" v-text="footerMenu.copyright"/>
                    <nav class="flex flex-col space-y-2 sm:space-y-0 sm:space-x-2 sm:flex-row lg:space-x-6">
                        <a v-for="bottomLink in footerMenu.bottomLinks" :href="bottomLink.link.url"
                           v-text="bottomLink.label" class="text-slate-400 hover:text-blue-600"/>
                    </nav>
                </div>
            </div>
            <!--/ Footer -->
        </footer>
    </div>
</template>

<script setup>
const config = useRuntimeConfig();
const storyblokApi = useStoryblokApi()

const {data} = await storyblokApi.get('cdn/stories/globals/footer', {
    version: config.STORYBLOK_STAGE,
    resolve_links: 'link',
})

const footerMenu = ref(null)
footerMenu.value = data.story.content

</script>